<template>
  <div
    v-if="data.fields"
    class="spotify-embed-wrapper"
  >
    <h4>{{data.fields.headline}}</h4>
    <p>{{data.fields.tagline}}</p>

    <div class='spotifyPlaceholder' :id="data.fields.spotifyID" style='position: relative'>
      <iframe :src="`https://anchor.fm/sensata-ux-research/embed/episodes/${data.fields.spotifyID}`" height="102px" width="400px" frameborder="0" scrolling="no"></iframe>
    </div>

  </div>
</template>

<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export default {
  name: 'SpotifyEmbed',

  transition: 'page',

  props: [ 'data' ],

  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.spotify-embed-wrapper {
  margin: auto;
  margin-bottom: 0px;
  margin-top: 30px;
}
.spotifyPlaceholder {
}
</style>